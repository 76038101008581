.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.app-container h1 {
  color: var(--green);
  padding: 1rem;
}
